var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('img', {
    staticClass: "banner mobile",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/banner-mobile.png"
    }
  }), _c('img', {
    staticClass: "banner tablet",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/banner-tablet.png"
    }
  }), _c('img', {
    staticClass: "banner desktop",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/banner-desktop.png"
    }
  }), _c('div', {
    staticClass: "form-area"
  }, [_c('img', {
    staticClass: "form-title",
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/form-title.svg')
    }
  }), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/kid.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.studentChineseName,
      expression: "eventForm.studentChineseName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "孩子名稱"
    },
    domProps: {
      "value": _vm.eventForm.studentChineseName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.eventForm, "studentChineseName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/kid-a.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.studentEnglishName,
      expression: "eventForm.studentEnglishName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "小孩英文名稱"
    },
    domProps: {
      "value": _vm.eventForm.studentEnglishName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.eventForm, "studentEnglishName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/kid-five.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.studentAge,
      expression: "eventForm.studentAge"
    }],
    attrs: {
      "type": "number",
      "placeholder": "孩子年齡",
      "step": "1",
      "min": "0"
    },
    domProps: {
      "value": _vm.eventForm.studentAge
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.eventForm, "studentAge", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/member.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.parentName,
      expression: "eventForm.parentName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長姓名"
    },
    domProps: {
      "value": _vm.eventForm.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.eventForm, "parentName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/phone.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.parentPhone,
      expression: "eventForm.parentPhone"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長電話"
    },
    domProps: {
      "value": _vm.eventForm.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.eventForm, "parentPhone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-input"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/regular-myth-englearning/mail.svg')
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.parentEmail,
      expression: "eventForm.parentEmail"
    }],
    attrs: {
      "type": "text",
      "placeholder": "家長email"
    },
    domProps: {
      "value": _vm.eventForm.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.eventForm, "parentEmail", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-privacy"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.eventForm.privacyPolicyCheck,
      expression: "eventForm.privacyPolicyCheck"
    }],
    attrs: {
      "id": "privacy",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.eventForm.privacyPolicyCheck) ? _vm._i(_vm.eventForm.privacyPolicyCheck, null) > -1 : _vm.eventForm.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.eventForm.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.eventForm, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.eventForm, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.eventForm, "privacyPolicyCheck", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "privacy"
    }
  }, [_vm._v("我同意 WUWOW 線上英文 "), _c('router-link', {
    attrs: {
      "to": {
        path: _vm.privacyUrl
      },
      "target": "_blank"
    }
  }, [_vm._v("隱私權政策")])], 1)]), _c('button', {
    staticClass: "submit",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.eventForm);
      }
    }
  }, [_vm._v("立即領取")])])])]), _vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _c('div', {
    staticClass: "reserve-class",
    style: `      background-image: url(${_vm.file.girlBG});      background-repeat: no-repeat;      background-position: center;      background-size: cover;    `,
    attrs: {
      "id": "reserve-class"
    }
  }, [_c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('div', {
    staticClass: "text-center mx-5 text-lioshutan"
  }, [_c('p', [_vm._v(":: Let's start ::")]), _c('h3', [_vm._v("免費為孩子")]), _c('h3', [_vm._v("預約一堂試聽課程")]), _c('div', {
    staticClass: "mx-1"
  }, [_c('div', {
    staticClass: "from-class position-relative mx-3 p-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentChineseName,
      expression: "formdata.studentChineseName"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "孩子姓名"
    },
    domProps: {
      "value": _vm.formdata.studentChineseName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentChineseName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentEnglishName,
      expression: "formdata.studentEnglishName"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "孩子英文名稱"
    },
    domProps: {
      "value": _vm.formdata.studentEnglishName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentEnglishName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentAge,
      expression: "formdata.studentAge"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "孩子年齡",
      "type": "number"
    },
    domProps: {
      "value": _vm.formdata.studentAge
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentAge", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentName,
      expression: "formdata.parentName"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "家長姓名"
    },
    domProps: {
      "value": _vm.formdata.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentPhone,
      expression: "formdata.parentPhone"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "家長電話"
    },
    domProps: {
      "value": _vm.formdata.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentPhone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentEmail,
      expression: "formdata.parentEmail"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "家長email"
    },
    domProps: {
      "value": _vm.formdata.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentEmail", $event.target.value);
      }
    }
  })]), _c('b-row', {
    staticClass: "form-check-box mb-3"
  }, [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.privacyPolicyCheck,
      expression: "formdata.privacyPolicyCheck"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "privacyPolicyCheck"
    },
    domProps: {
      "checked": Array.isArray(_vm.formdata.privacyPolicyCheck) ? _vm._i(_vm.formdata.privacyPolicyCheck, null) > -1 : _vm.formdata.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formdata.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formdata, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formdata, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formdata, "privacyPolicyCheck", $$c);
        }
      }
    }
  })]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('label', {
    staticClass: "form-check-label"
  }, [_vm._v("我同意WUWOW線上英文"), _c('a', {
    attrs: {
      "href": _vm.privacyUrl,
      "target": "_blank",
      "for": "privacyPolicyCheck"
    }
  }, [_vm._v("隱私權政策")])])])], 1), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('button', {
    staticClass: "text-light form-btn border-0 rounded-pill py-2 px-4 mt-3",
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.formdata);
      }
    }
  }, [_vm._v("預約試聽")])])], 1)])])]), _c('div', {
    staticClass: "d-none d-md-block d-lg-none"
  }, [_vm._m(5), _c('div', {
    staticClass: "mx-1"
  }, [_c('div', {
    staticClass: "w-75 from-class position-relative mx-3 p-4 mx-auto"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentChineseName,
      expression: "formdata.studentChineseName"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "孩子姓名"
    },
    domProps: {
      "value": _vm.formdata.studentChineseName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentChineseName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentEnglishName,
      expression: "formdata.studentEnglishName"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "孩子英文名稱"
    },
    domProps: {
      "value": _vm.formdata.studentEnglishName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentEnglishName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentAge,
      expression: "formdata.studentAge"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "孩子年齡",
      "type": "number"
    },
    domProps: {
      "value": _vm.formdata.studentAge
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentAge", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentName,
      expression: "formdata.parentName"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "家長姓名"
    },
    domProps: {
      "value": _vm.formdata.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentPhone,
      expression: "formdata.parentPhone"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "家長電話"
    },
    domProps: {
      "value": _vm.formdata.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentPhone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentEmail,
      expression: "formdata.parentEmail"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "家長email"
    },
    domProps: {
      "value": _vm.formdata.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentEmail", $event.target.value);
      }
    }
  })]), _c('b-row', {
    staticClass: "form-check-box mb-3"
  }, [_c('b-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.privacyPolicyCheck,
      expression: "formdata.privacyPolicyCheck"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "privacyPolicyCheck"
    },
    domProps: {
      "checked": Array.isArray(_vm.formdata.privacyPolicyCheck) ? _vm._i(_vm.formdata.privacyPolicyCheck, null) > -1 : _vm.formdata.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formdata.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formdata, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formdata, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formdata, "privacyPolicyCheck", $$c);
        }
      }
    }
  })]), _c('b-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('label', {
    staticClass: "form-check-label"
  }, [_vm._v("我同意WUWOW線上英文"), _c('a', {
    attrs: {
      "href": _vm.privacyUrl,
      "target": "_blank",
      "for": "privacyPolicyCheck"
    }
  }, [_vm._v("隱私權政策")])])])], 1), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('button', {
    staticClass: "text-light form-btn border-0 rounded-pill py-1 px-3 mt-3",
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.formdata);
      }
    }
  }, [_vm._v("預約試聽")])])], 1)])]), _c('div', {
    staticClass: "d-none d-md-none d-lg-block"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-center align-items-center py-5"
  }, [_vm._m(6), _c('div', {
    staticClass: "behind-card"
  }, [_c('div', {
    staticClass: "behind-card-content position-relative mx-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "m-0 mr-2"
  }, [_vm._v("孩子姓名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentChineseName,
      expression: "formdata.studentChineseName"
    }],
    domProps: {
      "value": _vm.formdata.studentChineseName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentChineseName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "m-0 mr-2"
  }, [_vm._v("孩子英文名稱")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentEnglishName,
      expression: "formdata.studentEnglishName"
    }],
    domProps: {
      "value": _vm.formdata.studentEnglishName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentEnglishName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "m-0 mr-2"
  }, [_vm._v("孩子年齡")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.studentAge,
      expression: "formdata.studentAge"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formdata.studentAge
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "studentAge", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "m-0 mr-2"
  }, [_vm._v("家長姓名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentName,
      expression: "formdata.parentName"
    }],
    domProps: {
      "value": _vm.formdata.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "m-0 mr-2"
  }, [_vm._v("家長電話")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentPhone,
      expression: "formdata.parentPhone"
    }],
    domProps: {
      "value": _vm.formdata.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentPhone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "m-0 mr-2"
  }, [_vm._v("家長email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.parentEmail,
      expression: "formdata.parentEmail"
    }],
    domProps: {
      "value": _vm.formdata.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formdata, "parentEmail", $event.target.value);
      }
    }
  })]), _c('b-row', {
    staticClass: "form-check-box mt-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formdata.privacyPolicyCheck,
      expression: "formdata.privacyPolicyCheck"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "privacyPolicyCheck"
    },
    domProps: {
      "checked": Array.isArray(_vm.formdata.privacyPolicyCheck) ? _vm._i(_vm.formdata.privacyPolicyCheck, null) > -1 : _vm.formdata.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formdata.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formdata, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formdata, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formdata, "privacyPolicyCheck", $$c);
        }
      }
    }
  })]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('label', {
    staticClass: "form-check-label"
  }, [_vm._v("我同意WUWOW線上英文"), _c('a', {
    attrs: {
      "href": _vm.privacyUrl,
      "target": "_blank",
      "for": "privacyPolicyCheck"
    }
  }, [_vm._v("隱私權政策")])])])], 1), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('button', {
    staticClass: "send-btn btn form-btn rounded-pill px-3",
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.formdata);
      }
    }
  }, [_vm._v("預約試聽")])])], 1)])])])]), _c('div', {
    staticClass: "lioshutan-footer bg-lioshutan py-4"
  }, [_c('div', {
    staticClass: "mobile py-4"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _vm._m(7), _vm._m(8), _vm._m(9)]), _c('div', {
    staticClass: "tablet"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row py-4"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _vm._m(10)]), _vm._m(11)])])]), _c('div', {
    staticClass: "desktop"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-around py-5"
  }, [_c('div', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _vm._m(12), _vm._m(13), _vm._m(14)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "feature"
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/events/feature-mobile.png"
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/events/feature-desktop.png"
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/events/feature-desktop.png"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "myth"
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/myth-mobile.png"
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/myth-desktop.png"
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/myth-desktop.png"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "question"
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/question-mobile.png"
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/question-desktop.png"
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/question-desktop.png"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "share"
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/share-mobile.png"
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/share-desktop.png"
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/share-desktop.png"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "award"
  }, [_c('img', {
    staticClass: "mobile",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/award-mobile.png"
    }
  }), _c('img', {
    staticClass: "tablet",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/award-desktop.png"
    }
  }), _c('img', {
    staticClass: "desktop",
    attrs: {
      "src": "https://cdn3.wuwow.tw/arts/award-desktop.png"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-row justify-content-center align-items-center py-5"
  }, [_c('div', {
    staticClass: "mx-5 text-lioshutan",
    staticStyle: {
      "width": "350px"
    }
  }, [_c('p', {
    staticClass: "area-english-title"
  }, [_vm._v(":: Let's start ::")]), _c('h1', [_vm._v("免費為孩子")]), _c('h1', [_vm._v("預約一堂試聽課程")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mx-5 text-lioshutan",
    staticStyle: {
      "width": "30%",
      "margin-bottom": "220px"
    }
  }, [_c('p', {
    staticClass: "area-english-title"
  }, [_vm._v(":: Let's start ::")]), _c('h1', {
    staticClass: "reserve-title"
  }, [_vm._v("免費為孩子")]), _c('h1', {
    staticClass: "reserve-title"
  }, [_vm._v("預約一堂試聽課程")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-light text-center contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light py-5",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-50 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-50 jr-logo mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-light text-center contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-sm-6"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center text-light mb-3",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]), _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-75 jr-logo mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-light contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "w-25 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-75 h-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "w-25 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-75 h-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])]);

}]

export { render, staticRenderFns }