<template lang="pug">
main
  .form
    .wrapper
      img.banner.mobile(src="https://cdn3.wuwow.tw/arts/banner-mobile.png")
      img.banner.tablet(src="https://cdn3.wuwow.tw/arts/banner-tablet.png")
      img.banner.desktop(src="https://cdn3.wuwow.tw/arts/banner-desktop.png")

      .form-area
        img.form-title(:src="require('@/assets/event/regular-myth-englearning/form-title.svg')")
        .form-input
          .icon: img(:src="require('@/assets/event/regular-myth-englearning/kid.svg')")
          input(v-model="eventForm.studentChineseName",type="text",placeholder="孩子名稱")
        .form-input
          .icon: img(:src="require('@/assets/event/regular-myth-englearning/kid-a.svg')")
          input(v-model="eventForm.studentEnglishName",type="text",placeholder="小孩英文名稱")

        .form-input
          .icon: img(:src="require('@/assets/event/regular-myth-englearning/kid-five.svg')")
          input(v-model="eventForm.studentAge",type="number",placeholder="孩子年齡",step="1" ,min="0")

        .form-input
          .icon: img(:src="require('@/assets/event/regular-myth-englearning/member.svg')")
          input(v-model="eventForm.parentName",type="text",placeholder="家長姓名")

        .form-input
          .icon: img(:src="require('@/assets/event/regular-myth-englearning/phone.svg')")
          input(v-model="eventForm.parentPhone",type="text",placeholder="家長電話")

        .form-input
          .icon: img(:src="require('@/assets/event/regular-myth-englearning/mail.svg')")
          input(v-model="eventForm.parentEmail",type="text",placeholder="家長email")

        .form-privacy
          input#privacy(v-model="eventForm.privacyPolicyCheck",type="checkbox")
          label(for="privacy") 我同意 WUWOW 線上英文&ensp;
            router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策

        button.submit(@click="submitForm(eventForm)", type="button") 立即領取

  .feature
    img.mobile(src="https://cdn3.wuwow.tw/arts/events/feature-mobile.png")
    img.tablet(src="https://cdn3.wuwow.tw/arts/events/feature-desktop.png")
    img.desktop(src="https://cdn3.wuwow.tw/arts/events/feature-desktop.png")

  .myth
    img.mobile(src="https://cdn3.wuwow.tw/arts/myth-mobile.png")
    img.tablet(src="https://cdn3.wuwow.tw/arts/myth-desktop.png")
    img.desktop(src="https://cdn3.wuwow.tw/arts/myth-desktop.png")

  .question
    img.mobile(src="https://cdn3.wuwow.tw/arts/question-mobile.png")
    img.tablet(src="https://cdn3.wuwow.tw/arts/question-desktop.png")
    img.desktop(src="https://cdn3.wuwow.tw/arts/question-desktop.png")

  .share
    img.mobile(src="https://cdn3.wuwow.tw/arts/share-mobile.png")
    img.tablet(src="https://cdn3.wuwow.tw/arts/share-desktop.png")
    img.desktop(src="https://cdn3.wuwow.tw/arts/share-desktop.png")

  .award
    img.mobile(src="https://cdn3.wuwow.tw/arts/award-mobile.png")
    img.tablet(src="https://cdn3.wuwow.tw/arts/award-desktop.png")
    img.desktop(src="https://cdn3.wuwow.tw/arts/award-desktop.png")

  //- 移植JR首頁，預約課程部分
  .reserve-class#reserve-class(
    :style="`\
      background-image: url(${file.girlBG});\
      background-repeat: no-repeat;\
      background-position: center;\
      background-size: cover;\
    `")

    //- 手機用
    .d-block.d-md-none
      .text-center.mx-5.text-lioshutan
        p :: Let's start ::
        h3 免費為孩子
        h3 預約一堂試聽課程

        .mx-1
          .from-class.position-relative.mx-3.p-4
            .d-flex.justify-content-between.align-items-center.my-5
              input.w-100(v-model="formdata.studentChineseName" placeholder="孩子姓名")
            .d-flex.justify-content-between.align-items-center.my-5
              input.w-100(v-model="formdata.studentEnglishName" placeholder="孩子英文名稱")
            .d-flex.justify-content-between.align-items-center.my-5
              input.w-100(v-model="formdata.studentAge" placeholder="孩子年齡" type="number")
            .d-flex.justify-content-between.align-items-center.my-5
              input.w-100(v-model="formdata.parentName" placeholder="家長姓名")
            .d-flex.justify-content-between.align-items-center.my-5
              input.w-100(v-model="formdata.parentPhone" placeholder="家長電話")
            .d-flex.justify-content-between.align-items-center.my-5
              input.w-100(v-model="formdata.parentEmail" placeholder="家長email")
            b-row.form-check-box.mb-3
              b-col(cols="2")
                input.form-check-input(v-model="formdata.privacyPolicyCheck" type="checkbox" id="privacyPolicyCheck")
              b-col(cols="10")
                label.form-check-label 我同意WUWOW線上英文
                  a(:href="privacyUrl" target="_blank" for="privacyPolicyCheck") 隱私權政策
            .d-flex.justify-content-center.align-items-center
              button.text-light.form-btn.border-0.rounded-pill.py-2.px-4.mt-3(@click="submitForm(formdata)") 預約試聽

    //- 給平板的尺寸
    .d-none.d-md-block.d-lg-none
      .d-flex.flex-row.justify-content-center.align-items-center.py-5
        .mx-5.text-lioshutan(style="width: 350px;")
          p.area-english-title :: Let's start ::
          h1 免費為孩子
          h1 預約一堂試聽課程

      .mx-1
        .w-75.from-class.position-relative.mx-3.p-4.mx-auto
          .d-flex.justify-content-between.align-items-center.my-5
            input.w-100(v-model="formdata.studentChineseName" placeholder="孩子姓名")
          .d-flex.justify-content-between.align-items-center.my-5
            input.w-100(v-model="formdata.studentEnglishName" placeholder="孩子英文名稱")
          .d-flex.justify-content-between.align-items-center.my-5
            input.w-100(v-model="formdata.studentAge" placeholder="孩子年齡" type="number")
          .d-flex.justify-content-between.align-items-center.my-5
            input.w-100(v-model="formdata.parentName" placeholder="家長姓名")
          .d-flex.justify-content-between.align-items-center.my-5
            input.w-100(v-model="formdata.parentPhone" placeholder="家長電話")
          .d-flex.justify-content-between.align-items-center.my-5
            input.w-100(v-model="formdata.parentEmail" placeholder="家長email")
          b-row.form-check-box.mb-3
            b-col(cols="1")
              input.form-check-input(v-model="formdata.privacyPolicyCheck" type="checkbox" id="privacyPolicyCheck")
            b-col(cols="11")
              label.form-check-label 我同意WUWOW線上英文
                a(:href="privacyUrl" target="_blank" for="privacyPolicyCheck") 隱私權政策
          .d-flex.justify-content-center.align-items-center
            button.text-light.form-btn.border-0.rounded-pill.py-1.px-3.mt-3(@click="submitForm(formdata)") 預約試聽

    //- 電腦用
    .d-none.d-md-none.d-lg-block
      .d-flex.flex-row.justify-content-center.align-items-center.py-5
        .mx-5.text-lioshutan(style="width: 30%;margin-bottom: 220px;")
          p.area-english-title :: Let's start ::
          h1.reserve-title 免費為孩子
          h1.reserve-title 預約一堂試聽課程

        .behind-card
          .behind-card-content.position-relative.mx-3
            .d-flex.justify-content-between.align-items-center.mb-4
              label.m-0.mr-2 孩子姓名
              input(v-model="formdata.studentChineseName")
            .d-flex.justify-content-between.align-items-center.mb-4
              label.m-0.mr-2 孩子英文名稱
              input(v-model="formdata.studentEnglishName")
            .d-flex.justify-content-between.align-items-center.mb-4
              label.m-0.mr-2 孩子年齡
              input(v-model="formdata.studentAge" type="number")
            .d-flex.justify-content-between.align-items-center.mb-4
              label.m-0.mr-2 家長姓名
              input(v-model="formdata.parentName")
            .d-flex.justify-content-between.align-items-center.mb-4
              label.m-0.mr-2 家長電話
              input(v-model="formdata.parentPhone")
            .d-flex.justify-content-between.align-items-center.mb-4
              label.m-0.mr-2 家長email
              input(v-model="formdata.parentEmail")
            b-row.form-check-box.mt-5
              b-col(cols="2")
                input.form-check-input(v-model="formdata.privacyPolicyCheck" type="checkbox" id="privacyPolicyCheck")
              b-col(cols="10")
                label.form-check-label 我同意WUWOW線上英文
                  a(:href="privacyUrl" target="_blank" for="privacyPolicyCheck") 隱私權政策
            .d-flex.justify-content-center.align-items-center
              button.send-btn.btn.form-btn.rounded-pill.px-3(@click="submitForm(formdata)") 預約試聽

  //- 移植JR首頁，footer
  .lioshutan-footer.bg-lioshutan.py-4
    //- 手機用
    .mobile.py-4
      .text-center
        a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
          h5.mb-4.text-light 合作提案
        a.text-decoration-none(:href="joinUsUrl" target="_blank")
          h5.mb-4.text-light 夥伴招募
        a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
          h5.mb-4.text-light 成為老師
      .text-light.text-center.contact
        h5 聯絡我們
        a.text-light(href="tel:0800885315") Tel: 0800-885315
        a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
        //- .d-flex.justify-content-center
        //-   img.mr-1(:src="file.fbSVG" width="50" height="50")
        //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
        //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
      a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.py-5
        img.w-50.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
        h6 WUWOW
        h6 最有效的線上英文
      a(href="/").d-flex.flex-column.align-items-center.text-light
        img.w-50.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
        h6 WUWOW Junior
        h6 專為孩子設計的線上英文

    //- 給平板的尺寸
    .tablet
      .container
        .row.py-4
          .col-sm-6
            .text-center
              a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
                h5.mb-4.text-light 合作提案
              a.text-decoration-none(:href="joinUsUrl" target="_blank")
                h5.mb-4.text-light 夥伴招募
              a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
                h5.mb-4.text-light 成為老師
            .text-light.text-center.contact
              h5 聯絡我們
              a.text-light(href="tel:0800885315") Tel: 0800-885315
              a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
              //- .d-flex.justify-content-center
              //-   img.mr-1(:src="file.fbSVG" width="50" height="50")
              //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
              //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
          .col-sm-6
            a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.mb-3
              img.w-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
              h6 WUWOW
              h6 最有效的線上英文
            a(href="/").d-flex.flex-column.align-items-center.text-light
              img.w-75.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
              h6 WUWOW Junior
              h6 專為孩子設計的線上英文
    //- 電腦用
    .desktop
      .d-flex.flex-row.justify-content-around.py-5
        div
          a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
            h5.mb-4.text-light 合作提案
          a.text-decoration-none(:href="joinUsUrl" target="_blank")
            h5.mb-4.text-light 夥伴招募
          a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
            h5.mb-4.text-light 成為老師
        .text-light.contact
          h5 聯絡我們
          a.text-light(href="tel:0800885315") Tel: 0800-885315
          a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
          //- .d-flex.justify-content-left
          //-   img.mr-1(:src="file.fbSVG" width="50" height="50")
          //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
          //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
        a.w-25(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
          h6 WUWOW
          h6 最有效的線上英文
        a.w-25(href="/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
          h6 WUWOW Junior
          h6 專為孩子設計的線上英文
</template>

<script>
import lioshutanApi from '@/api';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import { getEventMediaSource } from '@/utils/mediaSourse';
import httpCodeConstants from '@/constants/httpCode';
// JR 首頁 預約試聽版面用資料
import girlBG from '@/assets/girl_bg.jpg';
import 'bootstrap/dist/css/bootstrap.css';
// JR 首頁 footer版面用資料
import companyConstants from '@/constants/company';

export default {
  name: 'RegularMythEnglishLearning',
  data: () => ({
    // 上方橫幅預約
    eventForm: {
      studentChineseName: '',
      studentEnglishName: '',
      studentAge: '',
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      privacyPolicyCheck: false,
    },
    // 下方小孩背景預約課程表單
    formdata: {
      studentChineseName: '',
      studentEnglishName: '',
      studentAge: '',
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      privacyPolicyCheck: false,
    },
    pageMediaSource: '',
    privacyUrl: '/privacy',
    thankYouUrl: '/thank-you',
    // JR 首頁 預約試聽版面用資料
    file: {
      girlBG,
    },
    // JR 首頁 footer版面用資料
    cooperationUrl: companyConstants.INFO.COOPERATION_URL,
    joinUsUrl: companyConstants.INFO.JOIN_US_URL,
    pmRecruitmentUrl: companyConstants.INFO.PM_RECRUITMENT_URL,
  }),

  methods: {
    async submitForm(formdata) {
      const fieldChecked = this.fieldCheck(formdata.parentEmail, formdata.parentPhone, formdata.privacyPolicyCheck, formdata);
      if (fieldChecked) {
        const mediaSource = getEventMediaSource(this.$route.fullPath, this.$route.query);
        const noteInfo = this.getNoteInfo(formdata);
        const params = {
          name: formdata.studentChineseName,
          phone: formdata.parentPhone, // jr丟入家長的電話
          email: formdata.parentEmail, // jr丟入家長的email
          note: noteInfo, // 其餘資訊總和
          media_source: mediaSource,
        };
        const result = await lioshutanApi.common.setMediaSource(params);
        if (result.status === httpCodeConstants.SUCCESS) {
          this.$router.push({ path: '/event/regular_cycle_myth_englearning/thank-you' }); // 通往感謝頁
        }
      }
    },

    getNoteInfo(formdata) {
      // (iChannel)廠商需要接gid
      let merchantIChannelsGid = '';
      const queryGid = this.$route.query.gid;
      if (queryGid) {
        merchantIChannelsGid = `gid=${queryGid} |`;
      }
      const noteInfo = `${merchantIChannelsGid} 學員中文姓名: ${formdata.studentChineseName} | 學員英文姓名: ${formdata.studentEnglishName} | 學員年齡: ${formdata.studentAge} | 學員家長姓名: ${formdata.parentName}`;
      return noteInfo;
    },

    fieldCheck(email, phone, privacyPolicyCheck, formdata) {
      let fulfilled = true;
      Object.values(formdata).forEach((data) => {
        if (data === '') {
          fulfilled = false;
        }
      });
      if (!fulfilled) {
        alertFailedMessage('似乎還有些欄位尚未填寫完畢');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
main {
  --major-color: #216ddb;
  --secondary-color: #006bda;

  min-width: 375px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

/* 表單區域 */
.form {
  background-image: linear-gradient(180deg, #82c0ea 0%, #79b7ff 100%);

  .wrapper {
    position: relative;
    margin: 0 auto;
    /* max-width: 1280px; */

    &::after {
      content: "";
      position: absolute;
      margin: 20px 16px;
      pointer-events: none;
      width: calc(100% - 32px);
      height: calc(100% - 40px);
      top: 0;
      left: 0;

      border: 3.16022px solid #ffffff;
    }

    @media (min-width:1280px) {
      display: flex;
      justify-content: space-between;
      height: 100vh;
    }
  }

  img.banner {
    width: 100%;

    @media (min-width:1280px) {
      width: auto;
      /* max-width: 833px; */
    }
  }
}

.form-area {
  max-width: 400px;

  text-align: center;
  margin: 0 auto;

  padding: 0 32px;

  @media (min-width:1280px) {
    padding: 0;
    max-width: 320px;

    transform: translateX(-10%);
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
  }

  button.submit {
    margin: 20px 0 40px;

    border: none;
    color: white;
    border-radius: 25px;
    background: var(--secondary-color);

    font-size: 14px;
    line-height: 19px;
    padding: 10px 0 11px;
    width: 100%;
  }
}

.form-input {
  display: flex;
  border-radius: 5px;
  border: 1px solid var(--major-color);
  overflow: hidden;

  margin-top: 27px;

  &:nth-child(2) {
    margin-top: 30px;
  }

  .icon {
    border-right: 1px solid var(--major-color);
    background-color: white;
    flex-shrink: 0;
  }

  img {
    height: 40px;
    width: 40px;
  }

  input {
    flex-grow: 1;
    border: none;

    color: #393939;
    letter-spacing: 0.06em;

    &::placeholder {
      color: var(--major-color);
    }

    padding: 9px 10px;

    &:focus-visible {
      outline: none;
    }
  }
}

.form-privacy {
  margin-top: 27px;
  display: flex;

  justify-content: center;
  align-items: center;

  color: white;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.06em;

  input#privacy {
    height: 13px;
    width: 13px;
    margin-right: 5px;
  }

  label {
    margin: initial;
    font-weight: 400;
  }

  a {
    font-weight: 700;
    color: var(--secondary-color);
  }
}

/* 品牌特色 */
.feature {
  background: linear-gradient(180.65deg, #82C0EA 20.16%, #4FA0EA 73.18%);
  text-align: center;

  img {
    width: 100%;
  }

  padding-bottom: 30px;

  @media (min-width:576px) {
    padding: 0 40px 40px;
  }

  @media (min-width:1280px) {
    padding-bottom: 60px;
    img {
      max-width: 1120px;
    }
  }
}

/* 英文教育迷思 */
.myth {
  text-align: center;

  img {
    width: 100%;
  }
}

/* 問答 */
.question {
  background-image: linear-gradient(180deg, #82C0EA 16.99%, #57A4EA 79.48%);
  text-align: center;
  padding: 40px;

  img {
    max-width: 100%;

    @media (min-width:1280px) {
      max-width: 1000px;
    }
  }

  @media (min-width:576px) {
    padding: 50px 40px 65px;
  }

  @media (min-width:1280px) {
    padding: 55px
  }
}

/* 學生分享 */
.share {
  text-align: center;
  background-image: linear-gradient(180.32deg, #4F99EA 20.78%, #82C0EA 87.16%);

  img {
    width: 100%;
    margin-bottom: 40px;

    @media (min-width:576px) {
      margin-bottom: 45px;
    }

    @media (min-width:1280px) {
      margin-bottom: 80px;
      max-width: 1120px;
    }
  }
}

/* 獲獎殊榮 */
.award {
  background-image: linear-gradient(180.32deg, #4F99EA 20.78%, #82C0EA 87.16%);
  text-align: center;

  img {
    width: 100%;
    margin-bottom: 30px;

    @media (min-width:576px) {
      max-width: 100%;
      margin-bottom: 35px;
    }

    @media (min-width:1280px) {
      max-width: 1120px;
      margin-bottom: 45px;
    }

  }
}

/* 預約課程 */
#reserve-class {
  padding: 150px 0;

  input {
    /* background-color: red; */
    display: block;
    border: none;
    border-bottom: 1px solid #393939;
  }

  input:focus {
    outline: none;
  }

  *::placeholder {
    /* modern browser */
    color: #393939;
  }

  .form-btn {
    background-color: #45a2df;
  }

  .behind-card {
    position: relative;
    z-index: 5;
  }

  .behind-card-content {
    width: 600px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 80px 60px;
    font-size: 20px;
    color: #393939;
    opacity: 0.7;
  }

  .from-class {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    color: #393939;
    opacity: 0.7;
  }

  .reserve-class {
    padding: 150px 0;

    .reserve-title {
      font-size: 50px;
    }

    .subtitle {
      font-size: 20px;
      letter-spacing: 4px;
    }
  }

  .behind-card-content {
    input {
      width: 300px;
    }

    .form-check-input {
      width: 20px;
      height: 20px;
      right: 10px;
    }

    .send-btn {
      color: white;
      font-size: 20px;
      box-shadow: 0px 3px 6px #00000029;
      margin-top: 30px;

      &:hover {
        background-color: #3DA0E8;
        color: white;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .reserve-class {
      .reserve-title {
        font-size: 40px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .behind-card-content {
      width: 500px;

      input {
        width: 200px;
      }
    }

    .form-check-box {
      text-align: left;

      .form-check-input {
        right: 5px
      }
    }

    .reserve-class {
      .reserve-title {
        font-size: 35px;
      }
    }
  }

  @media screen and (max-width: 414px) {
    .subtitle {
      font-size: 15px;
      letter-spacing: 3px;
    }
  }
}

/* footer */
.lioshutan-footer {
  a {
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }

  .contact a {
    display: block;
    font-size: 1.09375rem
  }

  @media screen and (max-width: 992px) {
    .jr-logo {
      height: 100px;
    }
  }
}

/* RWD */
/* mobile first */
.tablet,
.desktop {
  display: none;
}

/* tablet */
@media (min-width: 576px) {
  .tablet {
    display: initial;
  }

  .mobile,
  .desktop {
    display: none;
  }
}

/* desktop */
@media (min-width: 1280px) {
  .desktop {
    display: initial;
  }

  .mobile,
  .tablet {
    display: none;
  }
}
</style>
